import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProperty } from '../../../actions/properties';


const ShoppingCenterSVG = ({ floor, properties }) => {
	const filters = useSelector(state => state.filters);
	const [svg, setSvg] = useState();
	const [showLegend, setShowLegend] = useState(true);
	const [legend, setLegend] = useState({});
	const svgRef = useRef(null);
	const dispatch = useDispatch();

	const showTooltip = (e, text) => {
		let tooltip = document.getElementById('svg-tooltip');
		tooltip.innerHTML = text;
		tooltip.style.display = 'block';
		tooltip.style.left = `${e.pageX + 10}px`;
		tooltip.style.top = `${e.pageY - 115}px`;
	};

	const hideTooltip = () => {
		const tooltip = document.getElementById('svg-tooltip');
		tooltip.style.display = 'none';
	};

	useEffect(() => {
		const XMLrequest = new XMLHttpRequest();
		XMLrequest.open('GET', floor.file_url, true);
		XMLrequest.onreadystatechange = (e) => {
			if (e.target.readyState === 4 && e.target.status === 200) {
				setSvg(e.target.response);
			}
		};
		XMLrequest.send(null);
	}, [floor.file_url]);

	useEffect(() => {
		let legendNode = svgRef.current.querySelector('g#legend');
		if (legendNode) {
			legendNode.remove();
		}
		const nodes = svgRef.current.querySelectorAll('g');
		const svgNode = svgRef.current.querySelector('svg');
		if (svgNode) {
			svgNode.insertAdjacentHTML('beforeend', `<g id="legend" />`);
			legendNode = svgNode.lastElementChild;
		}
		const viewBox = svgNode?.viewBox?.baseVal || svgNode?.viewbox?.baseVal;
		let fontSize = 6;
		let circleRadius = 5;
		let circleStrokeWidth = 0.6;
		if (viewBox?.width) {
			fontSize = viewBox.width / 100;
			circleRadius = viewBox.width / 120;
			circleStrokeWidth = viewBox.width / 2000;
		}
		let index = 1;
		let newLegend = {};

		nodes.forEach((node, i) => {
			const property = properties.find(property => property.reference === node.getAttribute('data-reference'));
			const polygon = node.children[0];
			let tooltipText = node.id;

			const handleClick = () => {
				dispatch(fetchProperty(property.id));
			};

			if (polygon) {
				if (property?.reference) {
					if (filters.sectors.includes(property.sector?.id)) {
						polygon.setAttribute('style', `fill:${property.sector?.color}`);
						if (showLegend) {
							const bbox = polygon.getBBox();
							const center = {
								x: bbox.x + bbox.width / 2,
								y: bbox.y + bbox.height / 2,
							};
							if (center) {
								legendNode.insertAdjacentHTML('beforeend', `<circle cx="${center.x}" cy="${center.y}" r="${circleRadius}" fill="#FFF" stroke="black" stroke-width="${circleStrokeWidth}" />`)
								const circle = legendNode.lastElementChild;
								legendNode.insertAdjacentHTML('beforeend', `<text x="${center.x}" y="${center.y}" font-size="${fontSize}" text-anchor="middle" alignment-baseline="central">${index}</text>`)
								const text = legendNode.lastElementChild;

								circle.onmousemove = (e) => showTooltip(e, tooltipText);
								circle.onmouseout = hideTooltip;
								circle.onclick = handleClick;
								text.onmousemove = (e) => showTooltip(e, tooltipText);
								text.onmouseout = hideTooltip;
								text.onclick = handleClick;
							}

							newLegend = { ...newLegend, [index]: property };
							index++;
						}
					}
					else {
						polygon.setAttribute('style', `fill:#FFF`);
					}
					polygon.onclick = handleClick;
					tooltipText = `${property.name} - ${property.tenant_name}`;
				}
				else {
					polygon.setAttribute('style', 'fill:#FFF');
				}

				polygon.onmousemove = (e) => showTooltip(e, tooltipText);
				polygon.onmouseout = hideTooltip;

				polygon.onclick = handleClickPolygon;
			}
		});

		setLegend(newLegend);
	}, [svg, properties, filters, showLegend, dispatch]);

	const handleMouseEnter = (property) => {
		const polygon = svgRef.current.querySelector(`g[data-reference="${property.reference}"]`)?.children[0];
		polygon.setAttribute('style', `stroke:#e4032b; stroke-width:3; fill:${property.sector?.color}`);
	};

	const handleMouseLeave = (property) => {
		const polygon = svgRef.current.querySelector(`g[data-reference="${property.reference}"]`)?.children[0];
		polygon.setAttribute('style', `fill:${property.sector?.color}`);
	};

	const handleClickPolygon = (e) => {
		if (e.target.style.stroke === 'rgb(228, 3, 43)') {
			e.target.style.stroke = '#000';
			e.target.style.strokeWidth = 1;
		}
		else {
			e.target.style.stroke = '#e4032b';
			e.target.style.strokeWidth = 3;
		}
	};

	return (
		<>
			<div className="custom-control custom-switch">
				<input type="checkbox" className="custom-control-input" id="legend" checked={showLegend} onChange={(e) => setShowLegend(e.target.checked)} />
				<label className="custom-control-label" htmlFor="legend">Legend</label>
			</div>
			<div id="svg-tooltip" />
			<div className="d-flex w-100 h-100">
				<div ref={svgRef} className="sc-svg" dangerouslySetInnerHTML={{ __html: svg }} />
				{showLegend && (
					<div className="sc-legend d-flex flex-column flex-wrap text-left mt-2 ml-2">
						{Object.keys(legend).map(key => (
							<p
								key={`legend_${key}`}
								className="m-1"
								onMouseEnter={() => handleMouseEnter(legend[key])}
								onMouseLeave={() => handleMouseLeave(legend[key])}
								onClick={() => dispatch(fetchProperty(legend[key].id))}
							>
								{`${key} ${legend[key].tenant_name || legend[key].name}`}
							</p>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default ShoppingCenterSVG;