import React from 'react'
import { FeatureGroup, GeoJSON } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';

import { selectProperties, fetchProperty } from '../../actions/properties';
import { selectMarker } from '../../actions/markers';
import { getRandomColor, removeDuplicates } from '../../services/utils';
import { fetchProperty as serviceFetchProperty } from '../../services/api';


const ParcelList = ({ properties, visibleParcels }) => {
	const currentProperties = useSelector(state => state.currentProperties);
	const displayedProperties = useSelector(state => state.displayedProperties);
	const selectedProperties = useSelector(state => (
		removeDuplicates([...state.mapControls.reachability.properties.filter(property => typeof property.id !== 'string').map(property => property.data), ...state.cartProperties])
	));
	const sectors = useSelector(state => state.sectors);
	const mapSettings = useSelector(state => state.mapSettings);
	const dispatch = useDispatch();

	const selectProperty = property => {
		if (currentProperties?.length > 0 && property.id === currentProperties[0].id) {
			dispatch(selectProperties([]));
		}
		else {
			dispatch(fetchProperty(property.id));
		}
	};

	const selectShoppingCenter = async (property) => {
		dispatch(selectMarker(null));
		if (currentProperties?.length > 1 && (property.parent_id === currentProperties[0].parent?.id || property.id === currentProperties[0].parent?.id)) {
			dispatch(selectProperties([]));
		}
		else {
			const fetchedProperty = await serviceFetchProperty(property.parent_id || property.id);
			let { siblings } = fetchedProperty;
			const parent = fetchedProperty.parent || fetchedProperty;
			siblings[0] = { ...siblings[0], parent };
			dispatch(selectProperties(siblings));
		}
	};

	const handleClick = property => {
		dispatch(selectMarker(null));

		if (!!(property.type !== 'sc' || property.parent_id)) {
			selectProperty(property);
		}
		else {
			selectShoppingCenter(property);
		}
	};

	if (!visibleParcels) {
		return null;
	}

	let geoJSONProps = {
		weight: mapSettings?.parcelStrokeWidth || 1,
		color: mapSettings?.parcelStrokeColor || '#FFFFFF'
	};

	const parcels = properties.aggregations?.geo?.buckets.filter((property => property.geometry !== null)).map(bucket => {
		const property = bucket.items.hits.hits[0]._source;
		const sector = sectors.find(sector => property.sector_id === sector.id);
		const color = sector?.color || getRandomColor(property.sector_id);
		const parcelGeometry = {
			"type": "Feature",
			"geometry": property.geometry
		};

		if (displayedProperties.length > 0 && !displayedProperties.includes(property.id) && !displayedProperties.includes(property.parent_id) && !selectedProperties.some(selectedProperty => selectedProperty.id === property.id)) {
			return null;
		}

		return (
			<FeatureGroup eventHandlers={{ click: () => handleClick(property) }} key={`parcel_${property.id}_${mapSettings?.parcelStrokeWidth}_${mapSettings?.parcelStrokeColor}`}>
				<GeoJSON data={parcelGeometry} fillColor={color} color={color} fillOpacity={0.5} {...geoJSONProps} key={JSON.stringify(property.geometry)} />
			</FeatureGroup>
		);
	});

	if (parcels) {
		return parcels;
	}

	return null;
};

export default ParcelList;