import { SELECT_PROPERTIES, FETCH_PROPERTY, TOGGLE_DRAGGABLE_PROPERTY } from 'actions/properties';

export default (state=null, action) => {
	switch (action.type) {
		case SELECT_PROPERTIES:
			return action.payload;
		case FETCH_PROPERTY:
			return [action.payload];
		case TOGGLE_DRAGGABLE_PROPERTY:
			const newState = [...state];
			newState[0].draggable = !newState[0].draggable;
			if (!!newState[0].draggable) {
				newState[0].old_lat = newState[0].lat;
				newState[0].old_lng = newState[0].lng;
			}
			else {
				delete newState[0].old_lat;
				delete newState[0].old_lng;
			}
			return newState;
		default:
			return state;
	}
};