import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { register } from '../../services/api';


const DEFAULT_VALUES = {
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    job_title: '',
    company_name: '',
    company_website: '',
    company_address: '',
    company_contact_email: ''
};

const Register = () => {
    const [form, setForm] = useState(DEFAULT_VALUES);
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setForm(state => ({ ...state, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);

        if (e?.currentTarget?.checkValidity()) {
            setLoading(true);
            const response = await register(form);
            if (response.success) {
                setValidated(false);
                setForm(DEFAULT_VALUES);
                setIsSubmit(true);
            }
            else {
                setError(response.message);
            }
            setLoading(false);
        }
    };

    return (
        <div className="login container-fluid vh-100">
            <div className="row h-100 overflow-auto">
                <div className="col-md-6 offset-md-6 d-flex flex-column justify-content-center align-items-center py-5">
                    <div className="d-flex flex-column align-items-stretch">
                        <img src="/img/logo.svg" alt="Cushman & Wakefield" className="img-fluid mb-5" />
                        <h1 className="text-primary mb-5">Welcome to the Store Locator</h1>
                        <p><b>Create an account</b></p>
                        {isSubmit ? (
                            <>
                                <p>Thanks for your subscription,<br/>we are going to review your account and send you a link to finalise your registration.</p>
                                <p>We will come back to you in maximum 48 hours.<br/>Please check your spam.You have created an account.</p>
                            </>
                        ) : (
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group className="form-group">
                                    <Form.Control type="email" name="email" value={form.email} onChange={handleChange} placeholder="Email*" required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="firstname" value={form.firstname} onChange={handleChange} placeholder="Firstname*" required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="lastname" value={form.lastname} onChange={handleChange} placeholder="Lastname*" required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="phone" value={form.phone} onChange={handleChange} placeholder="Phone number*" required />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="company_name" value={form.company_name} onChange={handleChange} placeholder="Company" />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="job_title" value={form.job_title} onChange={handleChange} placeholder="Job title" />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control as="textarea" rows={2} name="company_address" value={form.company_address} onChange={handleChange} placeholder="Company address" />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="company_website" value={form.company_website} onChange={handleChange} placeholder="Company website" />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Control type="text" name="company_contact_email" value={form.company_contact_email} onChange={handleChange} placeholder="Company contact email" />
                                </Form.Group>
                                {error && <div className="invalid-feedback d-block mb-3">{error}</div>}
                                <button type="submit" className="btn btn-lg btn-primary w-100 justify-content-center" disabled={loading}>
                                    {loading ? (
                                        <Spinner animation="border" size="sm" />
                                    ) : (
                                        'Sign up'
                                    )}
                                </button>
                            </Form>
                        )}
                        <Link className="text-secondary" to="/login">You have an account? Log in</Link>
                        <p className="copyright text-secondary">© Cushman & Wakefield 2020 All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;