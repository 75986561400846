import { divIcon } from 'leaflet';

export const getRandomColor = (n) => {
	n = (n * 30) % 360;
	return 'hsl(' + n + ', 90%, 60%)';
}

export const createPinPointIcon = (color="#e4032b", strokeColor="#FFF") => (
	divIcon({
		html: `
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32">
				<path className="bgcolor" fill="${color}" d="m 16.120403,0.54971247 c -7.2449109,0 -11.6515631,4.60812553 -11.6515631,11.73634753 0.1266159,5.387273 5.8369551,13.514151 11.5138381,19.277494 5.088599,-5.660452 11.38762,-14.122912 11.587785,-19.656214 0,-6.7483037 -5.767529,-11.35762753 -11.45006,-11.35762753 z"></path>
				<circle fill="#FFF" stroke="none" className="circle" cx="16.114332" cy="11.749545" r="5.6491666"></circle>
				<path fill="none" strokeWidth="2" stroke-alignment="outer" stroke="${strokeColor}" opacity="1" d="m 16.120403,0.54971247 c -7.2449109,0 -11.6515631,4.60812553 -11.6515631,11.73634753 0,0 -0.2117795,6.640749 11.5138381,19.214368 C 27.776714,17.993229 27.570463,11.90734 27.570463,11.90734 c 0,-6.7483037 -5.767529,-11.35762753 -11.45006,-11.35762753 z" className="stroke"></path>
			</svg>
		`,
		iconSize: [30, 30],
		iconAnchor: [15, 15],
		className: ''
	})
);

export const createClusterCustomIcon = (cluster, visible=true) => {
	var markers = cluster.getAllChildMarkers();
	var markerCount = 0;
	markers.forEach((m) => { markerCount += m.options.count; });

	return divIcon({
		html: `<span>${markerCount}</span>`,
		className: `marker-cluster-custom ${visible ? '' : 'd-none'}`,
		iconSize: [30, 30],
	});
};

export const createShoppingCenterIcon = (count, visible=true) => (
	divIcon({
		html: `<span>${count}</span>`,
		className: `marker-cluster-custom ${visible ? '' : 'd-none'}`,
		iconSize: [30, 30],
	})
);

export const removeDuplicates = array => (
	Array.from(new Set(array.map(o => o.id))).map(id => array.find(o => o.id === id))
);