import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Reachability from './Reachability';

import { selectMarker } from '../../actions/markers';


const MarkerPanel = () => {
	const currentMarker = useSelector(state => state.currentMarker);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(selectMarker(null));
	};

	if (!currentMarker) {
		return null;
	}

	return (
		<div className="panel p-3">
			<div className="d-flex justify-content-end mb-4">
				<button className="btn align-self-start" onClick={handleClose}><img src="/img/icons/close.svg" alt="Close" /></button>
			</div>
			{currentMarker.address && (
				<>
					<h5>Address</h5>
					<p>{currentMarker.address}</p>
				</>
			)}
			<Reachability property={currentMarker} />
		</div>
	);
};

export default MarkerPanel;