import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { authLogin } from '../../actions/auth';


const Login = () => {
	const auth = useSelector(state => state.auth);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (login && password) {
			await dispatch(authLogin({ login, password }));
		}
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			history.push('/');
		}
	}, [auth.isAuthenticated, history]);

	return (
		<div className="login container-fluid">
			<div className="row h-100">
				<div className="col-md-6 offset-md-6 d-flex flex-column justify-content-center align-items-center">
					<div className="d-flex flex-column align-items-stretch">
						<img src="/img/logo.svg" alt="Cushman & Wakefield" className="img-fluid mb-5" />
						<h1 className="text-primary mb-5">Welcome to the Store Locator</h1>
						<p><b>Sign in to your account</b></p>
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="login" className="sr-only">Email</label>
								<input className="form-control" placeholder="Email" type="text" name="login" value={login} onChange={(e) => setLogin(e.target.value)} />
							</div>
							<div className="form-group">
								<label htmlFor="password" className="sr-only">Password</label>
								<input className="form-control" placeholder="Password" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
							</div>
							{auth?.errors?.login && (
								<p className="text-danger">{auth.errors.login}</p>
							)}
							<button type="submit" className="btn btn-primary w-100 justify-content-center">Sign in</button>
						</form>
						<Link className="text-secondary" to="/forgot-password">Forgot password?</Link>
						<p className="copyright text-secondary">© Cushman & Wakefield 2020 All Rights Reserved</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;