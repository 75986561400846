import { UPDATE_FILTERS, RESET_FILTERS } from 'actions/filters';

const initialState = {
	q: '',
	types: ["hs", "sc", "ootr"],
	sectors: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FILTERS:
			return { ...state, ...action.payload};
		case RESET_FILTERS:
			return { ...state, ...initialState, ...action.payload };
		default:
			return state;
	}
};