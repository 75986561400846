import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectShoppingCenter } from '../../../actions/shoppingCenter';


const FloorList = () => {
	const currentShoppingCenter = useSelector(state => state.currentShoppingCenter);
	const dispatch = useDispatch();

	const handleClickFloor = (floor) => {
		dispatch(selectShoppingCenter({ ...currentShoppingCenter, currentFloor: floor }));
	};

	if (!currentShoppingCenter?.floors) {
		return null;
	}

	return currentShoppingCenter.floors?.map(floor => (
		<React.Fragment key={`floor_${floor.id}`}>
			<div className="d-flex cursor-pointer floor" onClick={() => handleClickFloor(floor)}>
				<h4 className="text-primary">{floor.name}</h4>
				<img src={floor.file_url} alt={floor.name} />
			</div>
			<hr />
		</React.Fragment>
	));
};

export default FloorList;