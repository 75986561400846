import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/common/PrivateRoute';
import MapContainer from 'components/map/MapContainer';
import Login from 'components/account/Login';
import Register from 'components/account/Register';
import ForgotPassword from 'components/account/ForgotPassword';
import ResetPassword from 'components/account/ResetPassword';

import 'assets/scss/main.scss';

const App = () => {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <PrivateRoute exact={true} path="/">
                        <MapContainer />
                    </PrivateRoute>
                    <Route exact={true} path="/login">
                        <Login />
                    </Route>
                    <Route exact={true} path="/register">
                        <Register />
                    </Route>
                    <Route exact={true} path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route exact={true} path="/reset_password">
                        <ResetPassword />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};

export default App;