import { FETCH_MAP_SETTINGS, UPDATE_MAP_SETTINGS, RESET_MAP_SETTINGS } from 'actions/mapSettings';

const initialState = {
	markerStrokeColor: '#FFFFFF',
	markerStrokeWidth: 1,
	markerRadius: 10,
	showMarkers: true,
	tooltipOpacity: 0.8,
	tooltipMinFontSize: 8,
	tooltipMaxFontSize: 10,
	tooltipDirection: 'auto',
	showTooltips: true,
	parcelStrokeColor: '#FFFFFF',
	parcelStrokeWidth: 1,
	showParcels: true
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_MAP_SETTINGS:
			return { ...state, ...action.payload };
		case UPDATE_MAP_SETTINGS:
			localStorage.setItem('mapSettings', JSON.stringify({ ...state, ...action.payload }));
			return { ...state, ...action.payload };
		case RESET_MAP_SETTINGS:
			localStorage.removeItem('mapSettings');
			return initialState;
		default:
			return state;
	}
};