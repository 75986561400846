import * as services from 'services/api';

export const FETCH_SECTORS = 'FETCH_SECTORS';

export const fetchSectors = () => async dispatch => {
	const results = await services.fetchSectors();
	if(results) {
		dispatch(fetchSectorsAsync(results));
	}
};

const fetchSectorsAsync = sectors => ({
	type: FETCH_SECTORS,
	payload: sectors
});