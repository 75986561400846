import { combineReducers } from 'redux';
import auth from './auth';
import currentProperties from './currentProperties';
import properties from './properties';
import displayedProperties from './displayedProperties';
import sectors from './sectors';
import filters from './filters';
import mapControls from './mapControls';
import mapSettings from './mapSettings';
import currentShoppingCenter from './currentShoppingCenter';
import cartProperties from './cartProperties';
import currentMarker from './currentMarker';

export default combineReducers({
	auth,
	currentProperties,
	properties,
	displayedProperties,
	sectors,
	filters,
	mapControls,
	mapSettings,
	currentShoppingCenter,
	cartProperties,
	currentMarker
});
