import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { GeoAltFill, ArrowsMove, ArrowRightShort, ArrowLeftShort } from 'react-bootstrap-icons';
import { isMobileOnly } from 'react-device-detect';

import PDFDownloadButton from '../common/pdf/PDFDownloadButton';
import PropertyDocument from './pdf/PropertyDocument';
import FloorList from './shopping-center/FloorList';
// import TicketButton from './TicketButton';
import Reachability from './Reachability';
import HideShowPropertiesButton from './HideShowPropertiesButton';

import { selectProperties, toggleDraggableProperty, moveProperty, updateGeometryProperty } from '../../actions/properties';
import { selectShoppingCenter } from '../../actions/shoppingCenter';
import { addPropertyToCart, removePropertyFromCart } from '../../actions/properties';
import { moveProperty as apiMoveProperty } from '../../services/api';


const TYPES = {
	'hs': 'High Street',
	'sc': 'Shopping Centre',
	'ootr': 'Out of Town Retail'
};

const PropertyPanel = () => {
	const currentProperties = useSelector(state => state.currentProperties);
	const currentShoppingCenter = useSelector(state => state.currentShoppingCenter);
	const cartProperties = useSelector(state => state.cartProperties);
	const currentUser = useSelector(state => state.auth.currentUser);
	const dispatch = useDispatch();
	const [showMoreDescription, setShowMoreDescription] = useState(false);
	const [open, setOpen] = useState(true);
	const isStaff = currentUser?.is_staff || currentUser?.is_admin;

	useEffect(() => {
		if ((currentProperties && currentProperties[0] && currentShoppingCenter?.properties?.length > 0) && !(currentProperties[0].parent?.id === currentShoppingCenter.properties[0].parent.id || currentProperties[0].id === currentShoppingCenter.properties[0].parent.id)) {
			dispatch(selectShoppingCenter(null));
		}
	}, [currentProperties]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!currentProperties || !currentProperties[0]) {
		return null;
	}

	const property = currentProperties[0];
	const currentDate = new Date();
	const filename = `export_property_${property.name}_${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`;
	const isInCart = cartProperties.map(p => p.id).includes(property.id);

	const handleClose = async () => {
		if (property.draggable) {
			await handleCancelMoveProperty();
		}
		dispatch(selectProperties([]));
		dispatch(selectShoppingCenter(null));
	};

	const toggleShowMoreDescription = () => {
		setShowMoreDescription(currentState => !currentState);
	};

	const selectProperty = () => {
		dispatch(addPropertyToCart(property));
	};

	const deselectProperty = () => {
		dispatch(removePropertyFromCart(property.id));
	};

	const handleToggleDraggableProperty = () => {
		dispatch(toggleDraggableProperty());
	};

	const handleCancelMoveProperty = () => {
		const properties = [...currentProperties];
		const position = { lat: properties[0].old_lat, lng: properties[0].old_lng };
		properties[0] = { ...currentProperties[0], draggable: false };
		properties[0].lat = position.lat;
		properties[0].lng = position.lng;
		delete properties[0].old_lat;
		delete properties[0].old_lng;
		dispatch(selectProperties(properties));
		dispatch(moveProperty(property.id, position));
	};

	const handleSavePropertyLocation = async () => {
		const response = await apiMoveProperty(property.id, { lat: property.lat, lng: property.lng });
		handleToggleDraggableProperty();

		if (response.geometry && typeof response.geometry !== 'string') {
			dispatch(updateGeometryProperty(property.id, response.geometry));
		}
	};

	const handleTogglePanel = () => {
		setOpen(state => !state);
	};

	return (
		<div className={`panel panel-sliding p-3 ${open ? 'active' : ''}`}>
			{currentShoppingCenter && (
				<div className="panel-toggle">
					{open ? <ArrowRightShort size={25} onClick={handleTogglePanel} /> : <ArrowLeftShort size={25} onClick={handleTogglePanel} />}
				</div>
			)}
			<div className="d-flex justify-content-between mb-4">
				<h3 className="mb-0">{property.tenant_name}</h3>
				<button className="btn align-self-start" onClick={handleClose}><img src="/img/icons/close.svg" alt="Close" /></button>
			</div>

			<h5>Address</h5>
			<p>{property.street} {property.street_number}, {property.city}</p>

			<div className="row">
				<div className="col-md-6">
					<h5>Type</h5>
					<p>{TYPES[property.type]}</p>
				</div>
				{property.surface > 0 && (
					<div className="col-md-6">
						<h5>Surface</h5>
						<p>{property.surface}m²</p>
					</div>
				)}
				{property.type === 'sc' && (
					<div className="col-md-6">
						<h5>Name</h5>
						<p>{property.name}</p>
					</div>
				)}
				{!property.rentable && (
					<div className="col-md-6">
						<h5>Sector</h5>
						<p>{property.sector?.name}</p>
					</div>
				)}
				{(isStaff && property.reference) && (
					<div className="col-md-6">
						<h5>Reference</h5>
						<p>{property.reference}</p>
					</div>
				)}
			</div>

			{(isStaff && property.available_notes) && (
				<div className="mb-3">
					<h5>Description</h5>
					{property.available_notes.length <= 150 ? <p>{property.available_notes}</p> : (
						<>
							<p className="mb-0">{showMoreDescription ? property.available_notes : `${property.available_notes.substring(0, 150)}...`}</p>
							<button className="btn btn-link text-dark d-block p-0" onClick={toggleShowMoreDescription}>{showMoreDescription ? 'Show less' : 'Show more'}</button>
						</>
					)}
				</div>
			)}

			{!currentShoppingCenter?.currentFloor && <Reachability property={property} />}

			<div className="buttons d-flex mb-3">
				{(isStaff && property.efficy_url) && <Button variant="primary" href={property.efficy_url} target="_blank">Efficy</Button>}
				{(isStaff && property.rentable) && <PDFDownloadButton document={<PropertyDocument record={property} />} fileName={filename} />}
				<HideShowPropertiesButton property={property} />
				{isInCart ? (
					<button onClick={deselectProperty} className="btn btn-primary"><GeoAltFill />Remove</button>
				) : (
					<button onClick={selectProperty} className="btn btn-primary"><GeoAltFill />Add</button>
				)}
				{/* <TicketButton record={property} /> */}
			</div>
			{!isMobileOnly && isStaff && (
				<div className="buttons d-flex mb-3">
					{property.draggable ? (
						<>
							<button onClick={handleSavePropertyLocation} className="btn btn-primary"><ArrowsMove />Save</button>
							<button onClick={handleCancelMoveProperty} className="btn btn-secondary">Cancel</button>
						</>
					) : (
						<button onClick={handleToggleDraggableProperty} className="btn btn-primary"><ArrowsMove />Move</button>
					)}
				</div>
			)}

			<FloorList />
		</div>
	);
};

export default PropertyPanel;