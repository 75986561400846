/* Based on react-leaflet Tooltip */

import { useEffect } from 'react'
import { createOverlayComponent } from '@react-leaflet/core'
import { Tooltip as LeafletTooltip } from 'leaflet'

import { MAX_ZOOM, MIN_ZOOM } from '../../../services/settings'

const MAX_ZOOM_FONT_SIZE = 10;
const MIN_ZOOM_FONT_SIZE = 8;

const Tooltip = createOverlayComponent(
	function createTooltip(props, context) {
		return {
			instance: new LeafletTooltip(props, context.overlayContainer),
			context,
		}
	},
	function useTooltipLifecycle(
		element,
		context,
		props,
		setOpen,
	) {
		const { onClose, onOpen, minFontSize, maxFontSize } = props

		// Update font-size depending on zoom level
		const updateFontSize = () => {
			const { instance } = element
			const zoom = context.map.getZoom()
			let fontSize = maxFontSize
			if (zoom >= MAX_ZOOM) {
				fontSize = maxFontSize
			}
			else if (zoom <= MIN_ZOOM) {
				fontSize = minFontSize
			}
			else {
				fontSize = maxFontSize - (((maxFontSize - minFontSize) / (MAX_ZOOM - MIN_ZOOM)) * (MAX_ZOOM - zoom))
			}

			if (instance._container) {
				instance._container.style.fontSize = `${fontSize}px`

				if (instance._container.children[0] && props.direction === 'vertical') {
					instance._container.style.marginLeft = `-${instance._container.offsetWidth/2}px`;
					instance._container.children[0].style.marginTop = `${38 + instance._container.offsetWidth}px`;
				}
			}
		}

		useEffect(
			function addTooltip() {
				const container = context.overlayContainer
				if (container == null) {
					return
				}

				const { instance } = element

				const onTooltipOpen = (event) => {
					if (event.tooltip === instance) {
						updateFontSize()
						instance.update()
						setOpen(true)
						if (onOpen) {
							onOpen()
						}
					}
				}

				const onTooltipClose = (event) => {
					if (event.tooltip === instance) {
						setOpen(false)
						if (onClose) {
							onClose()
						}
					}
				}

				container.on({
					tooltipopen: onTooltipOpen,
					tooltipclose: onTooltipClose,
				})
				container.bindTooltip(instance)

				return function removeTooltip() {
					container.off({
						tooltipopen: onTooltipOpen,
						tooltipclose: onTooltipClose,
					})
					container.unbindTooltip()
				}
			},
			[element, context, setOpen, onClose, onOpen], // eslint-disable-line react-hooks/exhaustive-deps
		)

		useEffect(() => {
			updateFontSize()
		}, [context.map.getZoom()]) // eslint-disable-line react-hooks/exhaustive-deps

		// Add possibility to dynamically change permanent property
		useEffect(() => {
			const { instance } = element
			context.overlayContainer.unbindTooltip(instance).bindTooltip(instance, { permanent: props.permanent })
		}, [props.permanent]) // eslint-disable-line react-hooks/exhaustive-deps

		// Add possibility to dynamically change opacity property
		useEffect(() => {
			const { instance } = element
			context.overlayContainer.unbindTooltip(instance).bindTooltip(instance, { opacity: props.opacity })
		}, [props.opacity]) // eslint-disable-line react-hooks/exhaustive-deps

		// Add possibility to dynamically change font size properties
		useEffect(() => {
			updateFontSize()
		}, [props.minFontSize, props.maxFontSize]) // eslint-disable-line react-hooks/exhaustive-deps

		// Add possibility to dynamically change direction property
		useEffect(() => {
			const { instance } = element
			if (props.direction === 'vertical') {
				context.overlayContainer.unbindTooltip(instance).bindTooltip(instance, { direction: 'right' })
				if(instance._container) {
					instance._container.classList.add('leaflet-tooltip-vertical')
					instance._container.style.marginLeft = `-${instance._container.offsetWidth/2}px`;
					if (instance._container.children[0]) {
						instance._container.children[0].style.marginTop = `${38 + instance._container.offsetWidth}px`;
						instance._container.children[0].style.transform = `rotate(90deg)`;
					}
				}
			}
			else {
				context.overlayContainer.unbindTooltip(instance).bindTooltip(instance, { direction: props.direction })
				if(instance._container) {
					instance._container.classList.remove('leaflet-tooltip-vertical')
					instance._container.style.marginLeft = null;
					if (instance._container.children[0]) {
						instance._container.children[0].style.marginTop = null;
						instance._container.children[0].style.transform = null;
					}
				}
			}
		}, [element.instance._container, props.direction]) // eslint-disable-line react-hooks/exhaustive-deps
	},
);

Tooltip.defaultProps = {
	opacity: 0.8,
	minFontSize: MIN_ZOOM_FONT_SIZE,
	maxFontSize: MAX_ZOOM_FONT_SIZE
};

export default Tooltip;