import axios from 'axios';
import qs from 'qs';
import { API_URL, OPENROUTESERVICE_API_KEY, GOOGLE_API_KEY } from './settings';


axios.interceptors.request.use(
	config => {
		if(!config.headers.Authorization) {
			const token = localStorage.getItem('token');
			if(token) {
				config.headers['Authorization'] = token;
			}
		}
		return config;
	},
	error => {
		Promise.reject(error)
	}
);

/* Account */
export const login = async (data) => {
	const response = await axios.post(`${API_URL}/account/login`, data);
	return response.data;
}

export const forgotPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/forget_password`, data);
	return response.data;
};

export const resetPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/reset_password`, data);
	return response.data;
};

export const fetchCurrentUser = async () => {
	const response = await axios.get(`${API_URL}/account`);
	return response.data;
};

export const updateAccount = async (data) => {
	const response = await axios.put(`${API_URL}/account`, data);
	return await response.data;
};

export const register = async (data) => {
	const response = await axios.post(`${API_URL}/account/register`, data);
	return response.data;
};

/* Properties */
export const fetchProperties = async (data) => {
	const queryData = { ...data };
	const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}`  : '';
	let response = await axios.get(`${API_URL}/properties/search${query}`);
	return response.data.results;
};

export const searchProperties = async (data) => {
	const queryData = { ...data };
	const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}`  : '';
	let response = await axios.get(`${API_URL}/properties${query}`);
	return response.data;
};

export const fetchProperty = async (id) => {
	let response = await axios.get(`${API_URL}/properties/${id}`);
	return response.data;
};

export const fetchSectors = async () => {
	let { data } = await axios.get(`${API_URL}/sectors`);
	return data;
};

export const createTicket = async (data) => {
	const response = await axios.post(`${API_URL}/tickets`, data);
	return response.data;
};

export const moveProperty = async (id, data) => {
	const response = await axios.post(`${API_URL}/properties/${id}/move`, data);
	return response.data;
};

/* OpenRouteService */
const orsHeaders = { headers: { 'Authorization': OPENROUTESERVICE_API_KEY, 'Content-Type': 'application/geo+json; charset=utf-8' } };
const TRAVEL_MODES = {
	'driving': 'driving-car',
	'cycling': 'cycling-regular',
	'walking': 'foot-walking'
};
export const fetchReachability = async ({ travelMode, locations, range }) => {
	const profile = travelMode ? TRAVEL_MODES[travelMode] : 'driving-car';
	const data = {
		locations,
		range,
		attributes: ["area", "reachfactor", "total_pop"]
	};
	const response = await axios.post(`https://api.openrouteservice.org/v2/isochrones/${profile}`, data, orsHeaders);
	const { features } = response.data;
	return await { features };
};

/* Google Maps */
export const getAddressFromLatLng = async (lat, lng) => {
	const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`);
	const { results } = await response.json();
	return results?.length > 0 ? results[0].formatted_address : null;
};

/* Stats */
export const fetchStatsSocioDemo = async (data) => {
	const response = await axios.post(`${API_URL}/stats/socio_demo`, data);
	return response.data;
};