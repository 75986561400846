import { fetchProperties } from './properties';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const updateFilters = filters => (dispatch, getState) => (
	Promise.resolve(dispatch({
		type: UPDATE_FILTERS,
		payload: filters
	})).then(() => dispatch(fetchProperties(getState().filters)))
);

export const resetFilters = filters => (dispatch, getState ) => (
	Promise.resolve(dispatch({
		type: RESET_FILTERS,
		payload: filters
	})).then(() => dispatch(fetchProperties(getState().filters)))
);
