import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-bootstrap-icons';

import { updateFilters } from '../actions/filters';
import { updateMapControls } from '../actions/mapControls';
import { selectProperties, fetchProperty } from '../actions/properties';
import { selectMarker } from '../actions/markers';

import { searchProperties } from '../services/api';

let autoComplete;

const SearchBar = () => {
	const currentProperties = useSelector(state => state.currentProperties);
	const [ready, setReady] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const dispatch = useDispatch();
	const autoCompleteRef = useRef(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(updateFilters({ q: searchTerm }));
	};

	const handleClickProperty = (property) => {
		document.getElementsByClassName('pac-container')[0].style.setProperty('display', 'none', 'important');
		dispatch(updateMapControls({ center: [property.lat, property.lng] }));

		dispatch(selectMarker(null));
		if (currentProperties?.length > 0 && property.id === currentProperties[0].id) {
			dispatch(selectProperties([]));
		}
		else {
			dispatch(fetchProperty(property.id));
		}
	};

	useEffect(() => {
		const timerId = setTimeout(async () => {
			if (searchTerm.length >= 3 || searchTerm.length === 0) {
				const container = document.getElementsByClassName('pac-container')[0];
				container.querySelectorAll('.pac-property').forEach(el => el.remove());
				dispatch(updateFilters({ q: searchTerm }));

				// TODO: find another solution? Rewrite the component from scratch?
				if (searchTerm.length >= 3) {
					const results = await searchProperties({ q: searchTerm, page_size: 3 });
					for (const property of results) {
						const div = document.createElement("div");
						div.classList.add('pac-property');
						div.classList.add('pac-item');
						const span = document.createElement('span');
						span.classList.add('pac-item-query');
						span.append(`${property.tenant_name || ''} ${property.street} ${property.street_number}, ${property.city}`);
						div.append(span);
						div.addEventListener('click', () => handleClickProperty(property));
						container.prepend(div);
					}
				}
			}
		}, 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchTerm, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleScriptLoad = () => {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{ componentRestrictions: { country: ["be", "lu"] } }
		);
		autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
		autoComplete.addListener("place_changed", () => handlePlaceSelect());
		setTimeout(() => {
			setReady(true);
		}, 500);

	};

	const handlePlaceSelect = () => {
		document.getElementsByClassName('pac-container')[0].querySelectorAll('.pac-property').forEach(el => el.remove());
		const place = autoComplete.getPlace();
		if (place.geometry?.viewport) {
			setSearchTerm('');

			const viewport = [
				[place.geometry.viewport.getSouthWest().lat(), place.geometry.viewport.getSouthWest().lng()],
				[place.geometry.viewport.getNorthEast().lat(), place.geometry.viewport.getNorthEast().lng()]
			];
			dispatch(updateMapControls({
				viewport,
				marker: {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
					address: place.formatted_address
				}
			}));
		}
	};

	const initAutoComplete = (func) => {
		if (typeof window.google != 'undefined') {
			func();
		}
		else {
			setTimeout(() => {
				((func) => {
					initAutoComplete(func);
				})(func)
			}, 500);
		}
	};

	useEffect(() => {
		initAutoComplete(handleScriptLoad);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (ready) {
			const container = document.getElementsByClassName('pac-container')[0];

			if (container) {
				window.google.maps.event.addDomListener(document.body, 'click', (e) => {
					if ((e.target.tagName !== 'BODY') && (e.target.parentElement?.className !== 'pac-container') && (e.target.parentElement?.className !== 'pac-item') && (e.target.parentElement?.className !== 'pac-property') && (e.target.tagName !== 'INPUT')) {
						container.style.setProperty('display', 'none', 'important');
					}
					else {
						container.style.setProperty('display', 'block', 'important');
					}
				});
			}
		}
	}, [ready]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="input-group">
				<input
					type="text"
					className="form-control bg-dark text-white"
					aria-label="Search"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					ref={autoCompleteRef}
					placeholder=""
				/>
				<div className="input-group-append">
					<button className="btn btn-outline-dark " type="button" onClick={handleSubmit}>
						<span className="d-lg-block d-md-none">Search</span>
						<span className="d-none d-md-block d-lg-none"><SearchIcon size={16} /></span>
					</button>
				</div>
			</div>
		</form>
	);
};

export default SearchBar;