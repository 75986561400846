import React from 'react'
import { CircleMarker, Marker, LayerGroup } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import Tooltip from '../common/leaflet/Tooltip';
import SelectedMarker from './markers/SelectedMarker';

import { selectProperties, fetchProperty, moveProperty } from '../../actions/properties';
import { selectMarker } from '../../actions/markers';
import { updateMapControls } from '../../actions/mapControls';
import { getRandomColor } from '../../services/utils';
import { fetchProperty as serviceFetchProperty } from '../../services/api';
import { createShoppingCenterIcon, removeDuplicates } from '../../services/utils';


const MarkerList = ({ properties, visibleTooltips, visibleMarkers }) => {
	const currentProperties = useSelector(state => state.currentProperties);
	const displayedProperties = useSelector(state => state.displayedProperties);
	const selectedProperties = useSelector(state => (
		removeDuplicates([...state.mapControls.reachability.properties.filter(property => typeof property.id !== 'string').map(property => property.data), ...state.cartProperties])
	));
	const sectors = useSelector(state => state.sectors);
	const mapSettings = useSelector(state => state.mapSettings);
	const dispatch = useDispatch();
	const opacity = visibleMarkers ? 1 : 0;

	const handleClickProperty = property => {
		dispatch(selectMarker(null));
		if (currentProperties?.length > 0 && property.id === currentProperties[0].id) {
			dispatch(selectProperties([]));
		}
		else {
			dispatch(fetchProperty(property.id));
		}
	};

	const handleClickSelectedMarker= (property) => {
		if (!!(property.type !== 'sc' || property.parent_id)) {
			handleClickProperty(property);
		}
		else {
			handleClickShoppingCenter(property);
		}
	};

	const handleClickCustomCluster = (e) => {
		dispatch(updateMapControls({ viewport: e.target.options.bounds }));
	};

	const handleClickShoppingCenter = async (property) => {
		dispatch(selectMarker(null));
		if (currentProperties?.length > 1 && (property.parent_id === currentProperties[0].parent?.id || property.id === currentProperties[0].parent?.id)) {
			dispatch(selectProperties([]));
		}
		else {
			const fetchedProperty = await serviceFetchProperty(property.parent_id || property.id);
			let { siblings } = fetchedProperty;
			const parent = fetchedProperty.parent || fetchedProperty;
			siblings[0] = { ...siblings[0], parent };
			dispatch(selectProperties(siblings));
		}
	};

	const handleMoveProperty = (property, position) => {
		if (position) {
			const properties = [...currentProperties];
			properties[0].lat = position.lat;
			properties[0].lng = position.lng;
			dispatch(moveProperty(property.id, position));
			dispatch(selectProperties(properties));
		}
	};

	const markers = properties.aggregations?.geo?.buckets.map(bucket => {
		const property = bucket.items.hits.hits[0]._source;

		// TODO: update this when data for shopping centers
		if (displayedProperties.length > 0 && !displayedProperties.includes(property.id) && !displayedProperties.includes(property.parent_id) && !selectedProperties.some(selectedProperty => selectedProperty.id === property.id)) {
			return null;
		}

		const sector = sectors.find(sector => property.sector_id === sector.id);
		const color = sector?.color || getRandomColor(property.sector_id);
		const center = [bucket.centroid.location.lat, bucket.centroid.location.lon];
		const isSelected = selectedProperties.some(selectedProperty => selectedProperty.id === property.id);

		if (isSelected) {
			return (
				<SelectedMarker
					key={property.id}
					property={property}
					color={color}
					onClick={() => handleClickSelectedMarker(property)}
					opacity={opacity}
				/>
			);
		}
		if (selectedProperties.some(p => p.id === (property.parent_id || property.id))) {
			return null;
		}

		if (bucket.doc_count > 1) {
			const icon = createShoppingCenterIcon(bucket.doc_count, visibleMarkers);
			const bounds = [[bucket.viewport.bounds.top_left.lat, bucket.viewport.bounds.top_left.lon], [bucket.viewport.bounds.bottom_right.lat, bucket.viewport.bounds.bottom_right.lon]];

			return (
				<Marker
					key={property.id}
					position={center}
					count={bucket.doc_count}
					icon={icon}
					property={property}
					eventHandlers={{ click: _.isEqual(bounds[0], bounds[1]) ? () => handleClickShoppingCenter(property) : handleClickCustomCluster }}
					bounds={bounds}
					opacity={opacity}
				/>
			);
		}

		if (currentProperties?.length === 1 && currentProperties[0].id === property.id) {
			return (
				<SelectedMarker
					key={property.id}
					property={currentProperties[0]}
					color={color}
					onClick={() => handleClickProperty(property)}
					draggable={currentProperties[0].draggable}
					move={(position) => handleMoveProperty(property, position)}
					opacity={opacity}
				/>
			);
		}

		let tooltipText = property.tenant_name ? property.tenant_name : `${property.street} ${property.street_number}`;
		if (sector?.name === 'Empty') {
			tooltipText = 'Empty';
		}

		return (
			<CircleMarker
				key={property.id}
				center={[property.lat, property.lng]}
				weight={1}
				eventHandlers={{ click: () => handleClickProperty(property) }}
				property={property}
				count={bucket.doc_count}
				radius={mapSettings?.markerRadius || 10}
				className="circle-marker"
				pathOptions={{
					opacity,
					fillOpacity: opacity,
					fillColor: color,
					color: mapSettings?.markerStrokeColor ||'#FFF',
					weight: mapSettings?.markerStrokeWidth || 1
				}}
				pane="markerPane"
			>
				<Tooltip permanent={visibleTooltips} opacity={mapSettings?.tooltipOpacity} maxFontSize={mapSettings?.tooltipMaxFontSize} minFontSize={mapSettings?.tooltipMinFontSize} direction={mapSettings?.tooltipDirection || 'auto'}>
					<div>
						{tooltipText}
					</div>
				</Tooltip>
			</CircleMarker>
		);
	});

	// Get the properties that are not in the store and must be displayed
	let missingSelectedProperties = selectedProperties.filter(selectedProperty => (
		properties.aggregations?.geo?.buckets.map(bucket => bucket.items.hits.hits[0]._source.id).indexOf(selectedProperty.id) === -1
	));

	// TODO refacto this for displayed properties (properties not displayed if panel closed and unzoomed)
	if (currentProperties?.length > 0
		&& !selectedProperties.some(property => property.id === currentProperties[0].id || property.id === currentProperties[0].parent?.id)
		&& !properties.aggregations?.geo?.buckets.some(bucket => bucket.items.hits.hits[0]._source.id === currentProperties[0].id || bucket.items.hits.hits[0]._source.id === currentProperties[0].parent?.id || bucket.items.hits.hits[0]._source.parent_id === currentProperties[0].parent?.id)
	) {
		if (currentProperties[0].parent && currentProperties[0].parent.lat && currentProperties[0].parent.lng) {
			missingSelectedProperties.push(currentProperties[0].parent);
		}
		else {
			missingSelectedProperties.push(currentProperties[0]);
		}
	}

	const selectedMarkers = missingSelectedProperties.map(property => (
		!!(property.type !== 'sc' || property.parent_id) ? 
			<SelectedMarker
				key={property.id}
				property={property}
				onClick={() => handleClickProperty(property)}
				color={sectors.find(sector => property.sector_id === sector.id)?.color}
				opacity={opacity}
			/>
		:
			<Marker
				key={property.id}
				position={[property.lat, property.lng]}
				count={property.children_count}
				icon={createShoppingCenterIcon(property.children_count, visibleMarkers)}
				property={property}
				eventHandlers={{ click: () => handleClickShoppingCenter(property) }}
				opacity={opacity}
			/>
	));

	return (
		<LayerGroup>
			{markers}
			{selectedMarkers}
		</LayerGroup>
	);
};

export default MarkerList;