import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link, Font } from '@react-pdf/renderer';

import { GOOGLE_API_KEY } from '../../../services/settings';
import gothamMedium from '../../../assets/fonts/Gotham-Medium.ttf';
import gothamBook from '../../../assets/fonts/Gotham-Book.ttf';

Font.register({
	family: 'Gotham', fonts: [
		{ src: gothamMedium },
		{ src: gothamBook, fontWeight: 200 },
	]
});

const styles = StyleSheet.create({
	page: {
		fontSize: 14,
		backgroundColor: '#000'
	},
	background: {
		position: 'absolute',
		minWidth: '100%',
		minHeight: '100%',
		height: '100%',
		width: 'auto',
		objectFit: 'cover',
		opacity: 0.25
	},
	header: {
		paddingHorizontal: 30,
		paddingVertical: 25,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: '#FFF'
	},
	logo: {
		width: 150,
		height: 'auto',
		marginBottom: 15
	},
	contactData: {
		fontFamily: 'Gotham',
		fontSize: 9
	},
	content: {
		backgroundColor: '#FFF'
	},
	contentHeader: {
		backgroundColor: '#9c9c9c',
		paddingHorizontal: 30,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'stretch',
		color: '#FFF'
	},
	textContentHeader: {
		fontSize: 11.25,
		fontFamily: 'Gotham',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	borderLeft: {
		borderLeftStyle: 'solid',
		borderLeftWidth: 1,
		borderLeftColor: '#FFF',
		opacity: 0.3,
		paddingRight: 20
	},
	borderLeftContent: {
		justifyContent: 'stretch',
		flexDirection: 'row'
	},
	titleType: {
		fontFamily: 'Gotham',
		fontSize: 19.5
	},
	toLetTitle: {
		fontFamily: 'Gotham',
		fontSize: 24.75,
		textTransform: 'uppercase'
	},
	title: {
		fontFamily: 'Gotham',
		fontSize: 15,
		color: '#e4032b',
		marginBottom: 5
	},
	subtitle: {
		color: '#6C6D6D',
		fontFamily: 'Gotham',
		fontSize: 9.75,
		marginBottom: 5
	},
	text: {
		fontFamily: 'Gotham',
		fontWeight: 200,
		fontSize: 12,
		marginBottom: 15
	},
	contentColumn: {
		width: '50%',
		paddingHorizontal: 10,
		paddingVertical: 15
	},
	imageList: {
		flexDirection: 'row',
		alignItems: 'flex-start'
	},
	image: {
		height: 100,
		width: 'auto',
		margin: 'auto',
		marginLeft: 0,
		marginRight: 5
	},
	imageMap: {
		width: '90%',
		height: 'auto'
	},
	footer: {
		color: '#FFF',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		padding: 8,
		fontFamily: 'Gotham',
		fontSize: 9
	},
	link: {
		textDecoration: 'none'
	},
});

const TYPES = {
	'hs': 'High Street',
	'ootr': 'Out of Town Retail',
	'sc': 'Shopping Centre'
};

const COLORS = {
	'hs': '#e4032b',
	'ootr': '#4986a5',
	'sc': '#9c9c9c'
};

const PropertyDocument = ({ record }) => (
	<Document>
		<Page size="A4" orientation="landscape" style={styles.page}>
			<Image fixed src="/img/background-login.png" style={styles.background} />
			<View fixed style={styles.header}>
				<View>
					<Image src="/img/logo.png" style={styles.logo} />
					<Text style={styles.titleType}>{TYPES[record.type]}</Text>
				</View>
				<View style={styles.contactData}>
					<Text>{record.contact_fullname}</Text>
					<Text>{record.contact_email}</Text>
					<Text>{record.contact_phone}</Text>
				</View>
			</View>
			<View style={styles.content}>
				<View fixed style={{ ...styles.contentHeader, backgroundColor: COLORS[record?.type] }}>
					<Text style={styles.toLetTitle}>To let</Text>
					<View style={styles.borderLeftContent}>
						<View style={styles.borderLeft} />
						<View style={styles.textContentHeader}>
							<Text >Reference {record.reference}</Text>
						</View>
					</View>
					{record.surface ? (
						<View style={styles.borderLeftContent}>
							<View style={styles.borderLeft} />
							<View style={styles.textContentHeader}>
								<Text style={styles.textContentHeader}>{record.surface} sq/m of commercial use</Text>
							</View>
						</View>
					) : null}
					<View style={styles.borderLeftContent}>
						<View style={styles.borderLeft} />
						<View style={styles.textContentHeader}>
							<Text style={styles.textContentHeader}>
								{record.asked_rent ? (
									`Annual Rent ${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' }).format(record.asked_rent)}`
								) : (
									'Annual Rent: on demand'
								)}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ flexDirection: 'row', padding: 15 }}>
					<View style={styles.contentColumn}>
						<Text style={styles.subtitle}>{`${record.street}${record.street_number && ` ${record.street_number}`}, ${record.city}`}</Text>
						<Image src = {`https://maps.googleapis.com/maps/api/staticmap?key=${GOOGLE_API_KEY}&size=500x450&markers=color:red|${record.lat},${record.lng}`} style={styles.imageMap} />
					</View>
					<View style={{ ...styles.contentColumn, justifyContent: 'space-between' }}>
						<View>
							<Text style={styles.title}>Overview</Text>
							<Text style={styles.text}>Frontage: {record.frontage}m</Text>
							<Text style={styles.title}>Informations</Text>
							<Text style={styles.text}>{record.retail_notes ? record.retail_notes : 'No information available.'}</Text>
						</View>
						<View>
							<View style={styles.imageList}>
								{/* <Image src="/img/pdf-background.jpg" style={styles.image} />
								<Image src="/img/pdf-background.jpg" style={styles.image} />
								<Image src="/img/pdf-background.jpg" style={styles.image} /> */}
							</View>
						</View>
					</View>
				</View>
			</View>

			<View fixed style={styles.footer}>
				<Link src="www.cushmanwakefield.be" style={styles.link}>www.cushmanwakefield.be</Link>
				<Text> | info.brussels@cushwake.com | +32 02 514 40 00</Text>
			</View>
		</Page>
	</Document>
);

export default PropertyDocument;
