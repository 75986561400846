import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { forgotPassword } from '../../services/api';


const ForgotPassword = () => {
	const [login, setLogin] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const response = await forgotPassword({ login });
		setLoading(false);
		if (response.success) {
			setMessage('An email has been sent');
		}
		else {
			setError(response.message);
		}
	};

	return (
		<div className="login container-fluid vh-100">
			<div className="row h-100">
				<div className="col-md-6 offset-md-6 d-flex flex-column justify-content-center align-items-center">
					<div className="d-flex flex-column align-items-stretch">
						<img src="/img/logo.svg" alt="Cushman & Wakefield" className="img-fluid mb-5" />
						<h1 className="text-primary mb-5">Welcome to the Store Locator</h1>
						<p><b>Forgot password</b></p>
						{message ? (
							<p>{message}</p>
						) : (
							<form onSubmit={handleSubmit}>
								{error && (<p className="text-danger">{error}</p>)}
								<div className="form-group">
									<label htmlFor="login" className="sr-only">Email</label>
									<input className="form-control" placeholder="Email" type="text" name="login" value={login} onChange={(e) => setLogin(e.target.value)} />
								</div>
								<button type="submit" className="btn btn-primary w-100 justify-content-center">
									{loading ? (
										<Spinner animation="border" size="sm" />
									) : (
										'Forgot password'
									)}
								</button>
							</form>
						)}
						<p className="copyright text-secondary">© Cushman & Wakefield 2020 All Rights Reserved</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;