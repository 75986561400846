export const RESET_MAP_SETTINGS = 'RESET_MAP_SETTINGS';
export const UPDATE_MAP_SETTINGS = 'UPDATE_MAP_SETTINGS';
export const FETCH_MAP_SETTINGS = 'FETCH_MAP_SETTINGS';

export const resetMapSettings = () => ({
	type: RESET_MAP_SETTINGS,
	payload: null
});

export const updateMapSettings = settings => ({
	type: UPDATE_MAP_SETTINGS,
	payload: settings
});

export const fetchMapSettings = () => {
	const mapSettings = JSON.parse(localStorage.getItem('mapSettings')) || {};

	return {
		type: FETCH_MAP_SETTINGS,
		payload: mapSettings
	};
};
