import * as services from 'services/api';

export const SELECT_PROPERTIES = 'SELECT_PROPERTIES';
export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const UPDATE_DISPLAYED_PROPERTIES = 'UPDATE_DISPLAYED_PROPERTIES';
export const UPDATE_CART_PROPERTIES = 'UPDATE_CART_PROPERTIES';
export const ADD_PROPERTY_TO_CART = 'ADD_PROPERTY_TO_CART';
export const REMOVE_PROPERTY_FROM_CART = 'REMOVE_PROPERTY_FROM_CART';
export const TOGGLE_DRAGGABLE_PROPERTY = 'TOGGLE_DRAGGABLE_PROPERTY';
export const MOVE_PROPERTY = 'MOVE_PROPERTY';
export const UPDATE_GEOMETRY_PROPERTY = 'UPDATE_GEOMETRY_PROPERTY';

export const selectProperties = properties => ({
	type: SELECT_PROPERTIES,
	payload: properties
});

export const fetchProperties = (filters) => async dispatch => {
	const results = await services.fetchProperties(filters);
	if(results) {
		dispatch(fetchPropertiesAsync(results));
	}
};

const fetchPropertiesAsync = properties => ({
	type: FETCH_PROPERTIES,
	payload: properties
});

export const fetchProperty = (id) => async dispatch => {
	const result = await services.fetchProperty(id);
	if(result) {
		dispatch(fetchPropertyAsync(result));
	}
};

const fetchPropertyAsync = property => ({
	type: FETCH_PROPERTY,
	payload: property
});

export const moveProperty = (id, position) => ({
	type: MOVE_PROPERTY,
	payload: { id, position }
});

export const updateGeometryProperty = (id, geometry) => ({
	type: UPDATE_GEOMETRY_PROPERTY,
	payload: { id, geometry }
});

export const updateDisplayedProperties = ids => ({
	type: UPDATE_DISPLAYED_PROPERTIES,
	payload: ids
});

export const updateCartProperties = properties => ({
	type: UPDATE_CART_PROPERTIES,
	payload: properties
});

export const addPropertyToCart = property => ({
	type: ADD_PROPERTY_TO_CART,
	payload: property
});

export const removePropertyFromCart = id => ({
	type: REMOVE_PROPERTY_FROM_CART,
	payload: id
});

export const toggleDraggableProperty = () => ({
	type: TOGGLE_DRAGGABLE_PROPERTY,
	payload: null
});