import React, { useState, useEffect } from 'react';
import { ArrowRightShort, ArrowLeftShort } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';

import Reachability from '../Reachability';
import FloorList from './FloorList';
import HideShowPropertiesButton from '../HideShowPropertiesButton';

import { selectProperties, fetchProperty } from '../../../actions/properties';
import { selectShoppingCenter } from '../../../actions/shoppingCenter';


const ShoppingCenterPanel = () => {
	const currentProperties = useSelector(state => state.currentProperties);
	const currentShoppingCenter = useSelector(state => state.currentShoppingCenter);
	const dispatch = useDispatch();
	const [showMoreDescription, setShowMoreDescription] = useState(false);
	const [open, setOpen] = useState(true);

	useEffect(() => {
		dispatch(selectShoppingCenter({ properties: currentProperties, floors: shoppingCenter.floors }));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(selectShoppingCenter({ properties: currentProperties, floors: shoppingCenter.floors }));
	}, [currentProperties]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = () => {
		dispatch(selectProperties(null));
		dispatch(selectShoppingCenter(null));
	};

	const toggleShowMoreDescription = () => {
		setShowMoreDescription(currentState => !currentState);
	};

	const handleTogglePanel = () => {
		setOpen(state => !state);
	};

	if (!currentProperties || !currentProperties[0]) {
		return null;
	}

	const shoppingCenter = currentProperties[0]?.parent || currentProperties[0];

	return (
		<div className={`panel panel-sliding p-3 ${open ? 'active' : ''}`}>
			<div className="panel-toggle">
				{open ? <ArrowRightShort size={25} onClick={handleTogglePanel} /> : <ArrowLeftShort size={25} onClick={handleTogglePanel} />}
			</div>
			<div className="d-flex justify-content-between align-items-end mb-4">
				<h3 className="mb-0">{shoppingCenter.name}</h3>
				<button className="btn align-self-start" onClick={handleClose}><img src="/img/icons/close.svg" alt="Close" /></button>
			</div>

			{shoppingCenter.description && (
				<div className="mb-3">
					<h5>Description</h5>
					{shoppingCenter.description.length <= 150 ? <p>{shoppingCenter.description}</p> : (
						<>
							<p className="mb-0">{showMoreDescription ? shoppingCenter.description : `${shoppingCenter.description.substring(0, 150)}...`}</p>
							<button className="btn btn-link text-dark d-block p-0" onClick={toggleShowMoreDescription}>{showMoreDescription ? 'Show less' : 'Show more'}</button>
						</>
					)}
				</div>
			)}

			{!currentShoppingCenter?.currentFloor && <Reachability property={shoppingCenter} />}

			<FloorList />

			{shoppingCenter.floors?.length === 0 && (
				currentProperties.map(property => (
					property.reference && (
						<React.Fragment key={`property_${property.id}`}>
							<div onClick={() => dispatch(fetchProperty(property.id))} className="cursor-pointer">
								<div className="row align-items-center">
									{/* <div className="col-md-4">
										<img src="/img/pdf-background.jpg" className="img-fluid" alt={property.name} />
									</div> */}
									<div className="col-md-12">
										<h4>{property.name}</h4>
										<p className="mb-0">{property.tenant_name}</p>
									</div>
								</div>
							</div>
							<hr />
						</React.Fragment>
					)
				))
			)}

			<div className="buttons d-flex">
				<HideShowPropertiesButton property={shoppingCenter} />
			</div>
		</div>
	);
};

export default ShoppingCenterPanel;