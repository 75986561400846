import React, { useRef, useState } from 'react'
import { MapContainer, TileLayer, LayersControl, WMSTileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import { useSelector } from 'react-redux';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import MapEvents from './MapEvents';
import MarkerList from './MarkerList';
import ParcelList from './ParcelList';
import AnnotationsControl from './controls/AnnotationsControl';
import FullscreenControl from './controls/FullscreenControl';
import RotationControl from './controls/RotationControl';
import PegmanControl from './controls/PegmanControl';

import { GOOGLE_API_KEY, DEFAULT_CENTER, DEFAULT_ZOOM, MIN_ZOOM, MAX_ZOOM } from '../../services/settings';
import { mapStyle } from '../../services/mapStyle';


const googleMapsProps = {
	apiKey: GOOGLE_API_KEY,
	googleMapsLoaderConf: {
		version: 'weekly',
		libraries: ['places']
	},
	maxZoom: MAX_ZOOM
};


const LeafletMap = props => {
	const [visibleMarkersTooltips, setVisibleMarkersTooltips] = useState(false);
	const properties = useSelector(state => state.properties);
	const mapControls = useSelector(state => state.mapControls);
	const mapSettings = useSelector(state => state.mapSettings);
	const currentUser = useSelector(state => state.auth.currentUser);
	const layersControl = useRef(null);
	const reachabilityFeatureGroup = useRef(null);
	const annotationsFeatureGroup = useRef(null);
	const isStaff = currentUser?.is_staff || currentUser?.is_admin;

	return (
		<MapContainer {...props} minZoom={MIN_ZOOM}>
			<MapEvents reachabilityFeatureGroup={reachabilityFeatureGroup} annotationsFeatureGroup={annotationsFeatureGroup} setVisibleMarkersTooltips={setVisibleMarkersTooltips} />
			<LayersControl position="bottomright" ref={layersControl}>
				<LayersControl.BaseLayer name="Base Map" checked>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://api.mapbox.com/styles/v1/cwbel/ckmawl0zyglka17o5z5vs38nh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY3diZWwiLCJhIjoiY2s4anhkaGMyMDA3dTNob294djl1cmhhNSJ9.ljzWejkKqe1cyaNJ2EhiDg"
						maxZoom={MAX_ZOOM}
						maxNativeZoom={18}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Vector Map">
					<VectorTileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      					styleUrl="mapbox://styles/cwbel/ckmawl0zyglka17o5z5vs38nh"
      					accessToken="pk.eyJ1IjoiY3diZWwiLCJhIjoiY2s4anhkaGMyMDA3dTNob294djl1cmhhNSJ9.ljzWejkKqe1cyaNJ2EhiDg"
						maxZoom={MAX_ZOOM}
						maxNativeZoom={18}
    				/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="OpenStreetMap">
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						maxZoom={MAX_ZOOM}
						maxNativeZoom={18}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Google Maps Roadmap">
					<ReactLeafletGoogleLayer {...googleMapsProps} type='roadmap' styles={mapStyle} />
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Google Maps Satellite">
					<ReactLeafletGoogleLayer {...googleMapsProps} type='satellite' />
				</LayersControl.BaseLayer>

				<LayersControl.Overlay name="Cadastre Belgium">
					<WMSTileLayer
						layers={2}
						format="image/png"
						transparent
						url="https://eservices.minfin.fgov.be/arcgis/services/R2C/PlanParcellaire/MapServer/WMSServer/"
						maxZoom={MAX_ZOOM}
					/>
				</LayersControl.Overlay>
				<LayersControl.Overlay name="Cadastre Luxembourg">
					<TileLayer minZoom={10} maxZoom={MAX_ZOOM} maxNativeZoom={21} url="http://wmts1.geoportail.lu/opendata/wmts/cadastre/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png" opacity={0.7} />
				</LayersControl.Overlay>
			</LayersControl>
			<FullscreenControl position="topright" />
			<PegmanControl position="topright" />
			<RotationControl position="topright" />

			{isStaff && <AnnotationsControl position="topleft" annotationsFeatureGroup={annotationsFeatureGroup} />}
			<FeatureGroup ref={reachabilityFeatureGroup}>
				{mapControls.reachability.properties.map(({ color, features, data }) => (
					features.map((feature, i) => (
						<GeoJSON key={`feature_${data.id}_${i}`} data={feature} color={color} stroke={false} />
					))
				))}
			</FeatureGroup>
			<ParcelList properties={properties} visibleParcels={mapSettings?.showParcels} />
			<MarkerList properties={properties} visibleTooltips={!mapSettings?.showTooltips ? false : visibleMarkersTooltips} visibleMarkers={mapSettings?.showMarkers} />
		</MapContainer>
	);
};

LeafletMap.defaultProps = {
	center: DEFAULT_CENTER,
	zoom: DEFAULT_ZOOM,
	maxZoom: MAX_ZOOM,
	rotate: true
};

export default LeafletMap;