import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sliders } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';

import { updateFilters, resetFilters } from '../actions/filters';
import { updateMapControls } from '../actions/mapControls';
import { getRandomColor } from '../services/utils';


const Filters = () => {
	const sectors = useSelector(state => state.sectors);
	const filters = useSelector(state => state.filters);
	const showFilters = useSelector(state => state.mapControls.showFilters);
	const dispatch = useDispatch();
	const [ready, setReady] = useState(false);
	const [deselectAll, setDeselectAll] = useState(true);
	const location = useLocation();

	const handleToggleVisible = () => {
		dispatch(updateMapControls({ showFilters: !showFilters }));
	};

	const handleChangeType = (e) => {
		const type = e.target.value;
		const types = [...filters.types];

		if(e.target.checked) {
			types.push(type);
		}
		else {
			const index = types.indexOf(type);
			types.splice(index, 1);
		}
		dispatch(updateFilters({ types }));
	};

	const handleChangeSectors = (e) => {
		const sector_id = e.target.value;
		const sector_ids = [...filters.sectors];

		if(e.target.checked) {
			sector_ids.push(parseInt(sector_id));
		}
		else {
			const index = sector_ids.indexOf(parseInt(sector_id));
			sector_ids.splice(index, 1);
		}
		dispatch(updateFilters({ sectors: sector_ids }));
	};

	const handleResetFilters = () => {
		dispatch(resetFilters({ sectors: sectors.map(sector => sector.id) }));
	};

	const selectAllSectors = () => {
		dispatch(updateFilters({ sectors: sectors.map(sector => sector.id) }));
	};

	const deselectAllSectors = () => {
		dispatch(updateFilters({ sectors: [] }));
	};

	const toggleSelectedSectors = () => {
		if (deselectAll) {
			deselectAllSectors();
		}
		else {
			selectAllSectors();
		}

		setDeselectAll(prev => !prev);
	};

	useEffect(() => {
		if (sectors.length > 0) {
			setReady(true);
		}
	}, [sectors]);

	useEffect(() => {
		if (ready) {
			const query = new URLSearchParams(location.search);
			if (query.get('sectors')) {
				dispatch(updateFilters({ sectors: query.get('sectors').split(',').map(Number) }));
			}
			else {
				selectAllSectors();
			}
		}
	}, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={`container-filters container-fluid ${showFilters ? 'active' : ''}`}>
			<button className="btn-toggle btn btn-white" onClick={handleToggleVisible}>{showFilters ? <img src="/img/icons/chevron-down.svg" alt="Close" /> : <Sliders />}</button>
			<div className="filters row px-1 px-md-2">
				<div className="col col-md-2 col-lg-2 d-flex flex-column">
					<p className="title my-1">Select Market Segment(s)</p>
					<div className="d-flex d-md-block pt-4 pt-lg-2 py-2 ">
						<div className="custom-control custom-checkbox custom-round-checkbox mb-1 mr-1 mr-md-0">
							<input type="checkbox" value="hs" id="type_hs" className="custom-control-input" onChange={handleChangeType} checked={filters.types.includes("hs")} />
							<label className="custom-control-label" htmlFor="type_hs">High Streets</label>
						</div>
						<div className="custom-control custom-checkbox custom-round-checkbox mb-1 mr-1 mr-md-0">
							<input type="checkbox" value="sc" id="type_sc" className="custom-control-input" onChange={handleChangeType} checked={filters.types.includes("sc")} />
							<label className="custom-control-label" htmlFor="type_sc">Shopping Centres</label>
						</div>
						<div className="custom-control custom-checkbox custom-round-checkbox mb-1 mr-1 mr-md-0 ">
							<input type="checkbox" value="ootr" id="type_ootr" className="custom-control-input" onChange={handleChangeType} checked={filters.types.includes("ootr")} />
							<label className="custom-control-label" htmlFor="type_ootr">Out of Town Retail</label>
						</div>
					</div>
				</div>
				<div className="col col-md-10 col-lg-10 d-flex flex-column">
					<p className="title my-1">Choose by category</p>
					<div className="d-flex align-items-stretch justify-content-between bg-dark rounded h-100 flex-sm-row flex-column">
						<div className="sectors px-0 px-md-3 px-sm-2 py-2 align-self-center">
							{sectors.map(sector => (
								<React.Fragment key={`sector_${sector.id}`}>
									{/* FIXME: very very ugly, change to styled component? */}
									<style dangerouslySetInnerHTML={{ __html:`
										#checkbox-${sector.id} .custom-control-input~.custom-control-label::before, #checkbox-${sector.id} .custom-control-input:checked~.custom-control-label::before {
											background-color: ${sector.color || getRandomColor(sector.id)};
										}
									`}} />

									<div className="custom-control custom-checkbox" id={`checkbox-${sector.id}`}>
										<input type="checkbox" value={sector.id} id={`sector_${sector.id}`} className="custom-control-input" onChange={handleChangeSectors} checked={filters.sectors.includes(sector.id)} />
										<label className="custom-control-label" htmlFor={`sector_${sector.id}`}>{sector.name}</label>
									</div>
								</React.Fragment>
							))}
						</div>
						<div className="d-flex flex-row flex-sm-column">
							<div className="d-flex align-items-center pt-2 pb-2 px-3 flex-grow-1">
								<button className="btn btn-link text-white p-0" onClick={toggleSelectedSectors}>(De)select all</button>
							</div>

							<div className="reset-filters d-flex align-items-center pt-2 pb-sm-3 pb-2 px-3 flex-grow-1">
								<button className="btn btn-link p-0 text-white" onClick={handleResetFilters}>Reset filters</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Filters;