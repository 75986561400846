import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const PDFDownloadButton = props => {
	const [clicked, setClicked] = useState(false);

	const handleClick = () => {
		setClicked(true);
	};

	if(!clicked) {
		return <Button variant="primary" onClick={handleClick}>Generate PDF</Button>;
	}

	return (
		<PDFDownloadLink className="pdf-button" {...props}>
			{({ loading }) => (
				<Button variant="primary" disabled={loading}>
					{loading ? <><Spinner animation="border" size="sm" /> Loading...</> : 'Download'}
				</Button>
			)}
		</PDFDownloadLink>
	);
};

export default PDFDownloadButton;