import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

import Control from '../../common/Control';


const Icon = (props) => (
	<svg viewBox="0 0 20 20" {...props}>
		<polygon fill="#333333" points="6,9 10,1 14,9" />
		<polygon fill="#CCCCCC" points="6,11 10,19 14,11" />
	</svg>
);

const RotationControl = ({ position }) => {
	const map = useMap();
	const [style, setStyle] = useState({});
	const [angle, setAngle] = useState(0);
	const [isRotating, setIsRotating] = useState(false);
	const buttonRef = useRef();

	let previousPageX = 0;
	let previousPageY = 0;

	const rotate = (newAngle) => {
		map.setBearing(newAngle);
	}

	const handleMouseDown = () => {
		window.addEventListener('mouseup', handleMouseUp, true);
		window.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		window.removeEventListener('mousemove', handleMouseMove, true);
		window.removeEventListener('mouseup', handleMouseUp, true);
	};

	const handleMouseMove = (e) => {
		if(Math.abs(e.pageX - previousPageX) > Math.abs(e.pageY - previousPageY)) {
			setAngle(oldAngle => oldAngle + ((e.pageX - previousPageX) * 0.8));
			previousPageX = e.pageX;
		}
		else {
			setAngle(oldAngle => oldAngle + ((e.pageY - previousPageY) * 0.8));
			previousPageY = e.pageY;
		}

		setIsRotating(true);
	};

	// TODO: remove old method if the new one is accepted
	// const handleMouseMove = (e) => {
	// 	const center = [buttonRef.current.getBoundingClientRect().left + buttonRef.current.offsetWidth / 2, buttonRef.current.getBoundingClientRect().top + buttonRef.current.offsetHeight / 2];
	// 	setAngle(Math.atan2(e.pageX - center[0], - (e.pageY - center[1])) * (180 / Math.PI));
	// 	setIsRotating(true);
	// };

	const handleTouchStart = () => {
		window.addEventListener('touchend', handleTouchEnd, true);
		window.addEventListener('touchmove', handleTouchMove, true);
	};

	const handleTouchEnd = () => {
		window.removeEventListener('touchmove', handleTouchMove, true);
		window.removeEventListener('touchend', handleTouchEnd, true);
	};

	const handleTouchMove = (e) => {
		const center = [buttonRef.current.getBoundingClientRect().left + buttonRef.current.offsetWidth / 2, buttonRef.current.getBoundingClientRect().top + buttonRef.current.offsetHeight / 2];
		const touches = e.changedTouches;

		for (var i = 0; i < touches.length; i++) {
			setAngle(Math.atan2(touches[i].pageX - center[0], - (touches[i].pageY - center[1])) * (180 / Math.PI));
		}
		setIsRotating(true);
	};

	const handleReset = () => {
		if (!isRotating) {
			setAngle(0);
		}
		setIsRotating(false);
	};

	useEffect(() => {
		rotate(angle);
		setStyle({ transform: `rotate(${angle}deg)` });
	}, [angle]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Control position={position}>
			<button onClick={handleReset} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart} ref={buttonRef}>
				<Icon style={style} />
			</button>
		</Control>
	);
};

export default RotationControl;