import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

import { updateDisplayedProperties } from '../../actions/properties';

const HideShowPropertiesButton = ({ property }) => {
	const displayedProperties = useSelector(state => state.displayedProperties);
	const dispatch = useDispatch();

	const showAllProperties = () => {
		dispatch(updateDisplayedProperties([]));
	};

	const hideOtherProperties = () => {
		dispatch(updateDisplayedProperties([property.id]));
	};

	return (
		displayedProperties.includes(property.id) ? (
			<Button variant="primary" onClick={showAllProperties}><EyeFill /> Show all</Button>
		) : (
			<Button variant="primary" onClick={hideOtherProperties}><EyeSlashFill /> Hide all</Button>
		)
	)
}

export default HideShowPropertiesButton;