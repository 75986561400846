import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { resetMapSettings, updateMapSettings } from '../../actions/mapSettings';


const MapSettingsModal = ({ show, onHide }) => {
	const mapSettings = useSelector(state => state.mapSettings);
	const [markerStrokeColor, setMarkerStrokeColor] = useState(mapSettings.markerStrokeColor || '#FFFFFF');
	const [parcelStrokeColor, setParcelStrokeColor] = useState(mapSettings.parcelStrokeColor || '#FFFFFF');
	const dispatch = useDispatch();

	useEffect(() => {
		const timerId = setTimeout(() => {
			dispatch(updateMapSettings({ markerStrokeColor }));
		}, 300);

		return () => {
			clearTimeout(timerId);
		};
	}, [markerStrokeColor, dispatch]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			dispatch(updateMapSettings({ parcelStrokeColor }));
		}, 300);

		return () => {
			clearTimeout(timerId);
		};
	}, [parcelStrokeColor, dispatch]);

	const handleUpdateSettings = ({ target }) => {
		dispatch(updateMapSettings({ [target.id]: target.value }));
	};

	const handleUpdateCheckbox = ({ target }) => {
		dispatch(updateMapSettings({ [target.id]: target.checked }));
	};

	const handleChangeMarkerStrokeColor = ({ target }) => {
		setMarkerStrokeColor(target.value);
	};

	const handleChangeParcelStrokeColor = ({ target }) => {
		setParcelStrokeColor(target.value);
	};

	const handleChangeFontSize = ({ target }) => {
		dispatch(updateMapSettings({ tooltipMinFontSize: parseInt(target.value) }));
		dispatch(updateMapSettings({ tooltipMaxFontSize: parseInt(target.value) + 2 }));
	};

	const handleReset = () => {
		dispatch(resetMapSettings());
		setMarkerStrokeColor('#FFFFFF');
	};

	return (
		<>
			<Modal show={show} onHide={onHide} animation={false} backdropClassName="modal-backdrop-invisible">
				<Modal.Header closeButton>
					<Modal.Title>Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<h6>Markers</h6>
						<Form.Group>
							<Form.Check type="checkbox" label="Show markers" checked={mapSettings?.showMarkers} onChange={handleUpdateCheckbox} id="showMarkers" />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="markerRadius">Radius</Form.Label>
							<Form.Control type="number" min={1} value={mapSettings?.markerRadius} onChange={handleUpdateSettings} id="markerRadius" />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="markerStrokeWidth">Stroke width</Form.Label>
							<Form.Control type="number" min={0} step={0.5} value={mapSettings?.markerStrokeWidth} onChange={handleUpdateSettings} id="markerStrokeWidth" />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="markerStrokeColor">Stroke color</Form.Label>
							<Form.Control
								type="color"
								id="markerStrokeColor"
								value={markerStrokeColor}
								onChange={handleChangeMarkerStrokeColor}
							/>
						</Form.Group>

						<h6 className="mt-4">Tooltips</h6>
						<Form.Group>
							<Form.Check type="checkbox" label="Show tooltips" checked={mapSettings?.showTooltips} onChange={handleUpdateCheckbox} id="showTooltips" />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="tooltipOpacity">Opacity</Form.Label>
							<Form.Control type="range" min={0} max={1} step={0.05} value={mapSettings?.tooltipOpacity} onChange={handleUpdateSettings} id="tooltipOpacity" custom />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="tooltipFontSize">Font size</Form.Label>
							<Form.Control type="range" min={5} max={16} step={1} value={mapSettings?.tooltipMinFontSize} onChange={handleChangeFontSize} id="tooltipFontSize" custom />
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="tooltipDirection">Direction</Form.Label>
							<Form.Control as="select" value={mapSettings?.tooltipDirection} onChange={handleUpdateSettings} id="tooltipDirection" >
								<option value="auto">Auto</option>
								<option value="left">Left</option>
								<option value="right">Right</option>
								<option value="top">Top</option>
								<option value="bottom">Bottom</option>
								<option value="vertical">Vertical</option>
							</Form.Control>
						</Form.Group>
					</Form>

					<h6 className="mt-4">Parcels</h6>
					<Form.Group>
						<Form.Check type="checkbox" label="Show parcels" checked={mapSettings?.showParcels} onChange={handleUpdateCheckbox} id="showParcels" />
					</Form.Group>
					<Form.Group>
						<Form.Label htmlFor="parcelStrokeWidth">Stroke width</Form.Label>
						<Form.Control type="number" min={0} step={0.5} value={mapSettings?.parcelStrokeWidth} onChange={handleUpdateSettings} id="parcelStrokeWidth" />
					</Form.Group>
					<Form.Group>
						<Form.Label htmlFor="parcelStrokeColor">Stroke color</Form.Label>
						<Form.Control
							type="color"
							id="parcelStrokeColor"
							value={mapSettings?.parcelStrokeColor}
							onChange={handleChangeParcelStrokeColor}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="light" onClick={handleReset}>Reset</Button>
					<Button variant="light" onClick={onHide}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default MapSettingsModal;