import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import { ArrowRepeat, Camera, GearFill } from 'react-bootstrap-icons';
import domtoimage from 'dom-to-image';

import SearchBar from './SearchBar';
import AccountModal from './account/AccountModal';
import MapSettingsModal from './map/MapSettingsModal';

import { resetMap } from '../actions/mapControls';
import { authLogout } from '../actions/auth';


const Header = () => {
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const currentUser = useSelector(state => state.auth.currentUser);
    const properties = useSelector(state => state.properties);
    const dispatch = useDispatch();
    const isStaff = currentUser?.is_staff || currentUser?.is_admin;

    const handleResetMap = () => {
        dispatch(resetMap());
    };

    const handleTakeScreenshot = () => {
        const currentDate = new Date();
        const filename = `map_${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}.jpg`;
        const scale = 3;
        const domNode = document.getElementsByClassName('map-container')[0];
        domNode.classList.add('snapshot');

        domtoimage.toJpeg(domNode, {
            filter: (node) => (
                node.classList ? !(node.classList.contains('leaflet-control-container') || node.classList.contains('leaflet-google-mutant') || node.classList.contains('gm-style')) : true
            ),
            width: domNode.clientWidth * scale,
            height: domNode.clientHeight * scale,
            style: {
                transform: `scale(${scale})`,
                transformOrigin: 'top left'
            }
        }).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = filename;
            link.href = dataUrl;
            link.click();
            link.remove();
            domNode.classList.remove('snapshot');
        });
    };

    const handleLogout = () => {
        dispatch(authLogout());
    };

    const handleClickAccount = () => {
        setShowAccountModal(true);
    };

    const handleCloseAccountModal = () => {
        setShowAccountModal(false);
    };

    const handleClickSettings = () => {
        setShowSettingsModal(true);
    };

    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false);
    };

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="md">
                <Navbar.Brand href="/" className="p-2 mr-4">
                    <img src="/img/logo.png" alt="Cushman & Wakefield" className="img-responsive" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar" />
                <Navbar.Collapse id="navbar">
                    <SearchBar />
                    <p className="search-results m-0 ml-3 text-white">{properties.hits?.total.value ?? 0} result{properties.hits?.total.value > 1 ? 's' : ''}</p>
                    <ul className="nav bg-dark rounded mx-3">
                        <li className="nav-item">
                            <button className="nav-link btn btn-dark" onClick={handleClickSettings}><GearFill /><span>Map settings</span></button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-dark" onClick={handleResetMap}><ArrowRepeat /><span>Reset map</span></button>
                        </li>
                        {isStaff && (
                            <li className="nav-item">
                                <button className="nav-link btn btn-dark" onClick={handleTakeScreenshot}><Camera /><span>Take a snapshot</span></button>
                            </li>
                        )}
                    </ul>

                    {currentUser && (
                        <>
                            <Dropdown className="ml-auto">
                                <Dropdown.Toggle variant="" className="text-white">
                                    <span className="d-lg-block d-md-none">{currentUser?.firstname} {currentUser?.lastname}</span>
                                    <span className="avatar">
                                        <span>{currentUser?.firstname?.charAt(0) + currentUser?.lastname?.charAt(0)}</span>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleClickAccount}>Account</Dropdown.Item>
                                    <Dropdown.Item onClick={handleClickSettings}>Settings</Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <AccountModal show={showAccountModal} onHide={handleCloseAccountModal} />
                            <MapSettingsModal show={showSettingsModal} onHide={handleCloseSettingsModal} />
                        </>
                    )}
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default Header;