import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../Header';
import LeafletMap from './LeafletMap';
import Filters from '../Filters';
import ShoppingCenterModal from '../properties/shopping-center/ShoppingCenterModal';
import Panel from '../properties/Panel';

import { fetchSectors } from 'actions/sectors';
import { fetchCurrentUser } from 'actions/auth';


const MapContainer = () => {
	const showFilters = useSelector(state => state.mapControls.showFilters);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchSectors());
		dispatch(fetchCurrentUser());
	}, [dispatch]);

	return (
		<>
			<Header />
			<div className="d-flex overflow-hidden">
				<div className={`map-container ${showFilters ? 'active-filters' : ''}`}>
					<LeafletMap />
					<ShoppingCenterModal />
				</div>
				<Panel />
			</div>
			<Filters />
		</>
	);
};

export default MapContainer;