import React from 'react';
import { useSelector } from 'react-redux';

import PropertyPanel from './PropertyPanel';
import ShoppingCenterPanel from './shopping-center/ShoppingCenterPanel';
import MarkerPanel from './MarkerPanel';


const Panel = () => {
	const currentProperties = useSelector(state => state.currentProperties);
	const currentMarker = useSelector(state => state.currentMarker);

	if (currentMarker) {
		return <MarkerPanel />;
	}

	if (!currentProperties || currentProperties.length === 0) {
		return null;
	}

	if (currentProperties.length === 1) {
		return <PropertyPanel />;
	}
	else {
		return <ShoppingCenterPanel />;
	}
};

export default Panel;