import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Marker } from 'react-leaflet';

import Tooltip from '../../common/leaflet/Tooltip';

import { createPinPointIcon, getRandomColor } from '../../../services/utils';


const SelectedMarker = ({ color, property, onClick, draggable, move, ...props }) => {
	const [position, setPosition] = useState([property.lat, property.lng]);
	const mapSettings = useSelector(state => state.mapSettings);
	const markerRef = useRef(null);
	const icon = draggable ? createPinPointIcon(color, "#e4032b") : createPinPointIcon(color);
	let tooltipText = property.tenant_name ? property.tenant_name : `${property.street} ${property.street_number}`;
	if (property.sector?.name === 'Empty') {
		tooltipText = 'Empty';
	}

	if ((property.type === 'sc' || property.parent_id)) {
		color = '#e4032b';
	}

	if (!color) {
		color = getRandomColor(property.sector_id);
	}

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	const eventHandlers = ({
		dragend: () => {
			if (move) {
				const marker = markerRef.current;
				if (marker != null && setPosition) {
					const latLng = marker.getLatLng();
					setPosition([latLng.lat, latLng.lng]);
					move(latLng);
				}
			}
		},
		click: handleClick
	});

	useEffect(() => {
		if (!draggable && (position.lat !== property.lat || position.lng !== property.lng)) {
			setPosition([property.lat, property.lng]);
		}
	}, [draggable]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!position[0] || !position[1]) {
		return null;
	}

	return (
		<Marker
			position={position}
			icon={icon}
			property={property}
			count={1}
			draggable={draggable}
			ref={markerRef}
			eventHandlers={eventHandlers}
			{...props}
		>
			{tooltipText && <Tooltip permanent={true} opacity={mapSettings?.tooltipOpacity} maxFontSize={mapSettings?.tooltipMaxFontSize} minFontSize={mapSettings?.tooltipMinFontSize} direction={mapSettings?.tooltipDirection}>
				<div>
					{tooltipText}
				</div>
			</Tooltip>}
		</Marker>
	);
};

export default SelectedMarker;