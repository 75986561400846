import { UPDATE_CART_PROPERTIES, ADD_PROPERTY_TO_CART, REMOVE_PROPERTY_FROM_CART } from 'actions/properties';

export default (state = [], action) => {
	switch (action.type) {
		case UPDATE_CART_PROPERTIES:
			return action.payload;
		case ADD_PROPERTY_TO_CART:
			return [...state, action.payload];
		case REMOVE_PROPERTY_FROM_CART:
			const properties = state.filter(p => p.id !== action.payload);
			return properties;
		default:
			return state;
	}
};