import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import L from 'leaflet';
import qs from 'qs';

import { updateDisplayedProperties, selectProperties } from '../../actions/properties';
import { updateFilters } from '../../actions/filters';
import { updateMapControls } from '../../actions/mapControls';
import { selectShoppingCenter } from '../../actions/shoppingCenter';
import { selectMarker } from '../../actions/markers';
import { fetchMapSettings } from '../../actions/mapSettings';

import { DEFAULT_CENTER, DEFAULT_ZOOM, TOOLTIP_THRESHOLD_ZOOM } from '../../services/settings';
import { createPinPointIcon } from '../../services/utils';


const MapEvents = ({ reachabilityFeatureGroup, annotationsFeatureGroup, setVisibleMarkersTooltips }) => {
	// const [ready, setReady] = useState(false);
	// const properties = useSelector(state => state.properties);
	const mapControls = useSelector(state => state.mapControls);
	const filters = useSelector(state => state.filters);
	const currentProperties = useSelector(state => state.currentProperties);
	const [mapBounds, setMapBounds] = useState();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const map = useMapEvents({
		moveend: () => handleMove(),
		zoomend: () => handleZoom(),
		rotate: () => handleRotate(),
	});
	let lastZoom;

	const centerMap = (center, zoom) => {
		let bounds = new L.LatLngBounds();
		bounds.extend(center);
		map.fitBounds(bounds, { maxZoom: zoom });
	};

	const updateSearchFilters = () => {
		const zoom = map.getZoom();
		const bounds = map.getCircumscribedBounds();
		const ne = [bounds._northEast.lat, bounds._northEast.lng];
		const sw = [bounds._southWest.lat, bounds._southWest.lng];

		// L.rectangle(bounds, {color: "#ff7800", weight: 1}).addTo(map);
		setMapBounds({ zoom, ne, sw });
	};

	const handleZoom = (e) => {
		const zoom = map.getZoom();
		if (zoom >= TOOLTIP_THRESHOLD_ZOOM) {
			setVisibleMarkersTooltips(true);
		}
		else if (zoom < TOOLTIP_THRESHOLD_ZOOM && (!lastZoom || lastZoom >= TOOLTIP_THRESHOLD_ZOOM)) {
			setVisibleMarkersTooltips(false);
		}
		lastZoom = zoom;
	};

	const handleMove = () => {
		updateSearchFilters();
	};

	const handleRotate = () => {
		updateSearchFilters();
	};

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		if (query.get('ne') && query.get('sw')) {
			const ne = query.get('ne').split(',').map(Number);
			const sw = query.get('sw').split(',').map(Number);
			const southWest = L.latLng(sw[0], sw[1]);
			const northEast = L.latLng(ne[0], ne[1]);
			const bounds = L.latLngBounds(southWest, northEast);
			dispatch(updateMapControls({ viewport: bounds }));
		}

		const sectors = query.get('sectors')?.split(',') || [];
		const types = query.get('types')?.split(',') || ['hs', 'sc', 'ootr'];
		dispatch(updateFilters({ ...Object.fromEntries(query), sectors, types }));
		updateSearchFilters();

		dispatch(fetchMapSettings());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const query = qs.stringify(filters, { arrayFormat: 'comma', encode: false });
		history.replace(`${location.pathname}?${query}`);
	}, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const timerId = setTimeout(() => {
			dispatch(updateFilters(mapBounds));
		}, 125);

		return () => {
			clearTimeout(timerId);
		};
	}, [mapBounds, dispatch]);

	useEffect(() => {
		if (map && mapControls.center) {
			centerMap(mapControls.center, 13);
		}
	}, [mapControls.center]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (map && mapControls.viewport) {
			map.fitBounds(mapControls.viewport);
		}
	}, [mapControls.viewport]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (map && mapControls.marker) {
			const { marker } = mapControls;
			if (annotationsFeatureGroup && annotationsFeatureGroup.current) {
				annotationsFeatureGroup.current.addLayer(new L.marker([marker.lat, marker.lng], { icon: createPinPointIcon(), address: marker.address }));
			}
		}
	}, [mapControls.marker]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (map && mapControls.reset) {
			map.setView(new L.LatLng(DEFAULT_CENTER[0], DEFAULT_CENTER[1]), DEFAULT_ZOOM);
			// initBoundingBox();

			reachabilityFeatureGroup.current.clearLayers();
			if (annotationsFeatureGroup && annotationsFeatureGroup.current) {
				annotationsFeatureGroup.current.clearLayers();
			}
			dispatch(updateMapControls({ reset: false }));
			dispatch(updateDisplayedProperties([]));
			dispatch(selectProperties([]));
			dispatch(selectShoppingCenter(null));
			dispatch(selectMarker(null));
		}
	}, [mapControls.reset]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setTimeout(() => {
			map.invalidateSize();
		}, 250);
	}, [mapControls.showFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentProperties?.length === 0) {
			map.invalidateSize();
		}
	}, [currentProperties]); // eslint-disable-line react-hooks/exhaustive-deps

	// FIXME: bounding box disabled because bad position for some properties
	// const initBoundingBox = useCallback(() => {
	// 	const buckets = properties?.aggregations?.geo?.buckets;
	// 	const { bounds } = properties?.aggregations?.zoom_map;

	// 	if (buckets?.length > 0) {
	// 		map.fitBounds(new L.LatLngBounds([[bounds.top_left.lat, bounds.top_left.lon], [bounds.bottom_right.lat, bounds.bottom_right.lon]]));
	// 	}
	// }, [properties]); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	if (properties.aggregations?.geo?.buckets?.length > 0) {
	// 		setReady(true);
	// 	}
	// }, [properties]);

	// useEffect(() => {
	// 	if(ready) {
	// 		initBoundingBox();
	// 	}
	// }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

	return null;
};

export default MapEvents;