import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FeatureGroup } from 'react-leaflet';
import { PencilFill } from 'react-bootstrap-icons';

import Control from '../../common/Control';
import EditControl from '../../common/leaflet-draw/EditControl';
import ColorPicker from '../../common/ColorPicker';

import { selectMarker } from '../../../actions/markers';
import { selectProperties } from '../../../actions/properties';
import { createPinPointIcon } from '../../../services/utils';
import { getAddressFromLatLng } from '../../../services/api';

const DEFAULT_COLOR = '#e4032b';


const AnnotationsControl = ({ annotationsFeatureGroup, position }) => {
	const [drawingMode, setDrawingMode] = useState(false);
	const [color, setColor] = useState(DEFAULT_COLOR);
	const presetColors = [...new Set(useSelector(state => state.sectors.map(sector => sector.color)))];
	const currentMarker = useSelector(state => state.currentMarker);
	const dispatch = useDispatch();

	const toggleDrawingMode = () => {
		setDrawingMode(prev => !prev);
	};

	const handleChangeColor = (color) => {
		setColor(color);
	};

	const handleCreated = async (e) => {
		if (e.layerType === 'marker') {
			const address =  await getAddressFromLatLng(e.layer._latlng.lat, e.layer._latlng.lng);
			e.layer.options.address = address;
			const marker = { lat: e.layer?._latlng?.lat, lng: e.layer?._latlng?.lng, id: `l_${e.layer?._leaflet_id}`, address };
			dispatch(selectProperties([]));
			dispatch(selectMarker(marker));
		}
	};

	return (
		<FeatureGroup ref={annotationsFeatureGroup} eventHandlers={{
			click: (e) => {
				if (e.layer.editing._marker) {
					const marker = { lat: e.latlng.lat, lng: e.latlng.lng, id: `l_${e.sourceTarget._leaflet_id}`, address: e.layer.options.address };
					dispatch(selectProperties([]));
					if (currentMarker && marker.id === currentMarker.id) {
						dispatch(selectMarker(null));
					}
					else {
						dispatch(selectMarker(marker));
					}
				}
			},
		}}>
			<Control position={position} className="leaflet-bar color-control">
				<button onClick={toggleDrawingMode}>
					<PencilFill />
					<span className="sr-only">Drawing mode</span>
				</button>
				{drawingMode && <ColorPicker onChange={handleChangeColor} defaultColor={DEFAULT_COLOR} presetColors={presetColors} /> }
			</Control>
			{drawingMode && (
				<EditControl
					position={position}
					draw={{
						polyline: { shapeOptions: { color }},
						polygon: { shapeOptions: { color }},
						rectangle: { shapeOptions: { color }},
						circle: { shapeOptions: { color }},
						marker: {
							icon: createPinPointIcon(color)
						},
						circlemarker: false
					}}
					onCreated={handleCreated}
				/>
			)}
		</FeatureGroup>
	);
};

AnnotationsControl.defaultProps ={
	position: 'topleft'
};

export default AnnotationsControl;