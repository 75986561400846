import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ShoppingCenterSVG from './ShoppingCenterSVG';

import { selectShoppingCenter } from 'actions/shoppingCenter';


const ShoppingCenterModal = () => {
	const currentShoppingCenter = useSelector(state => state.currentShoppingCenter);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(selectShoppingCenter({ ...currentShoppingCenter, currentFloor: null }));
	};

	if(!currentShoppingCenter?.currentFloor) {
		return null;
	}

	return (
		<div className="card sc-card">
			<div className="d-flex justify-content-between align-items-end mt-2">
				<h4 className="mb-0 px-3 text-primary">{currentShoppingCenter.currentFloor.name}</h4>
				<button className="btn align-self-start" onClick={handleClose}><img src="/img/icons/close.svg" alt="Close" /></button>
			</div>
			<div className="sc-container">
				<ShoppingCenterSVG floor={currentShoppingCenter.currentFloor} properties={currentShoppingCenter.properties} />
			</div>
		</div>
	);
};

export default ShoppingCenterModal;